var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{ref:"headerTopWrapper",staticClass:"sf-header__top-wrapper",class:{ 'sf-header--transparent': _vm.isHeaderTransparent },on:{"mouseenter":function($event){_vm.isHovered = true},"mouseleave":function($event){_vm.isHovered = false}}},[_c('Header',{class:{ 'search-opened': _vm.isSearchOpen },attrs:{"is-header-transparent":_vm.isHeaderTransparent,"category-tree":_vm.categoryTree},on:{"is-search-open":function($event){_vm.isSearchOpen = true}},nativeOn:{"mouseleave":function($event){_vm.isHovered = false}},scopedSlots:_vm._u([{key:"logo",fn:function(){return [_c('SfLink',{attrs:{"link":_vm.localePath('home'),"aria-label":_vm.$t('Home')}},[_c('SfIcon',{staticStyle:{"--icon-width":"76px","--icon-height":"28px"},attrs:{"icon":"header_logo","color":_vm.isHeaderTransparent ? 'var(--togas-white-color)' : 'var(--togas-black-color)'}})],1)]},proxy:true},{key:"navigation",fn:function(){return [_c('HeaderNavigation',{attrs:{"category-tree":_vm.categoryTree},on:{"hideNavigation":_vm.toggleDesktopMenu}})]},proxy:true},{key:"aside",fn:function(){return [_c('div',{staticClass:"sf-header__switchers"},[(_vm.hasCurrencyToSelect)?_c('CurrencySelector',{staticClass:"mobile-only"}):_vm._e(),_vm._v(" "),_c('StoreSwitcher',{staticClass:"mobile-only"})],1)]},proxy:true},{key:"header-icons",fn:function(ref){
var activeIcon = ref.activeIcon;
return [_c('div',{staticClass:"sf-header__icons"},[(_vm.isAuthenticated)?_c('SfButton',{staticClass:"sf-button--pure sf-header__action",attrs:{"data-testid":"wishlistIcon","aria-label":"Wishlist"},on:{"click":function($event){_vm.$router.push(_vm.localePath('customer-my-wishlist'))}}},[_c('SfIcon',{staticClass:"sf-header__icon",staticStyle:{"--icon-width":"17px","--icon-height":"25px"},attrs:{"icon":"header_wishlist","color":_vm.isHeaderTransparent ? 'var(--togas-white-color)' : 'var(--togas-black-color)',"label":_vm.$t('Wishlist')}}),_vm._v(" "),(_vm.wishlistHasProducts)?_c('SfBadge',{staticClass:"sf-badge--number wishlist-badge"},[_vm._v("\n            "+_vm._s(_vm.wishlistItemsQty)+"\n          ")]):_vm._e()],1):_vm._e(),_vm._v(" "),_c('SfButton',{directives:[{name:"e2e",rawName:"v-e2e",value:('app-header-account'),expression:"'app-header-account'"}],staticClass:"sf-button--pure sf-header__action",attrs:{"data-testid":"accountIcon","aria-label":"Account"},on:{"click":function($event){_vm.isAuthenticated
            ? _vm.$router.push(_vm.localePath('customer-my-wishlist'))
            : _vm.$router.push(_vm.localePath('customer-login-register')
          )}}},[_c('SfIcon',{class:{ 'sf-header__icon is-active': activeIcon === 'account' },attrs:{"icon":"header_account","color":_vm.isHeaderTransparent ? 'var(--togas-white-color)' : 'var(--togas-black-color)',"size":"1.25rem","label":_vm.$t('Account')}})],1),_vm._v(" "),(_vm.isAuthenticated && !!_vm.userFirstName)?_c('span',{staticClass:"sf-header__action sf-header__user-name",on:{"click":function($event){_vm.isAuthenticated
            ? _vm.$router.push(_vm.localePath('customer-my-wishlist'))
            : _vm.$router.push(_vm.localePath('customer-login-register')
          )}}},[_vm._v("\n          "+_vm._s(_vm.$t('Welcome, ') + _vm.userFirstName)+"!\n        ")]):_vm._e(),_vm._v(" "),_c('SfButton',{directives:[{name:"e2e",rawName:"v-e2e",value:('app-header-cart'),expression:"'app-header-cart'"}],staticClass:"sf-button--pure sf-header__action",attrs:{"aria-label":"Go to cart"},on:{"click":_vm.toggleCartSidebar}},[_c('SfIcon',{staticStyle:{"--icon-width":"1.25rem","--icon-height":"1.25rem"},attrs:{"icon":"header_cart","color":_vm.isHeaderTransparent ? 'var(--togas-white-color)' : 'var(--togas-black-color)',"label":_vm.$t('Cart')}}),_vm._v(" "),(_vm.cartTotalItems)?_c('SfBadge',{staticClass:"sf-badge--number cart-badge"},[_vm._v("\n            "+_vm._s(_vm.cartTotalItems)+"\n          ")]):_vm._e()],1)],1)]}},{key:"search",fn:function(){return [_c('SearchBar',{attrs:{"is-search-open":_vm.isSearchOpen},on:{"set-is-open":function($event){_vm.isSearchOpen = $event; _vm.isHovered = false},"set-search-results-products":function($event){_vm.productSearchResults = $event},"set-search-results-categories":function($event){_vm.categorySearchResults = $event},"search-term":function($event){_vm.searchTerm = $event},"search-total-results":function($event){_vm.searchTotalResults = $event}}})]},proxy:true}])}),_vm._v(" "),(_vm.isSearchOpen)?_c('SearchResults',{attrs:{"visible":_vm.isSearchOpen,"product-search-results":_vm.productSearchResults,"category-search-results":_vm.categorySearchResults,"search-term":_vm.searchTerm,"search-total-results":_vm.searchTotalResults},on:{"close":_vm.clearSearch}}):_vm._e(),_vm._v(" "),(_vm.isSearchOpen || _vm.isDesktopMenuOpen || _vm.isMobileMenuOpen)?_c('SfOverlay',{attrs:{"visible":_vm.isSearchOpen || _vm.isDesktopMenuOpen || _vm.isMobileMenuOpen}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }