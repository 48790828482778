import { useContext } from "@nuxtjs/composition-api";

export const getBoutique = boutiqueData => ( boutiqueData.data?.boutiqueLandings?.items.length
  ? boutiqueData.data?.boutiqueLandings?.items[0]
  : null
);

export const getId = store => store
  ? store.boutique_id ?? ''
  : '';

export const getImage = store => store.thumbnail ?? '';

export const getName = (store) => (store ? store.name : '');

export const getCountryName = (store) => (store && store.address?.country_name ? store.address.country_name : '');

export const getPhones = (store) => {
  const phones = [];
  if (store && store.contacts?.length) {
    store.contacts.forEach((element) => {
      if (element.type === 'phone') phones.push(element.value);
    })
  }
  return phones;
};

export const getFormattedPhone = phoneString => `tel: +${+phoneString.replace(/[^\d]/g, '')}`;

export const getEmails = (store) => {
  const emails = [];
  if (store && store.contacts?.length) {
    store.contacts.forEach((element) => {
      if (element.type === 'email') emails.push(element.value);
    })
  }
  return emails;
};

export const getFormattedEmail = (emailString) => `mailto:${emailString.trim()}`;

export const getFullAddress = (store) => (store
  ? `${store.address?.street || ''}, ${store.address?.city || ''} ${store.address?.country_id || ''}`
  : ''
);

export const getPosition = (store) => (store
  ? { lat: +store.address?.latitude, lng: +store.address?.longitude }
  : ''
);

export const getBusinessHoursOutput = (store) => (store
    ? store.business_hours_output
    : []
);

export const getLink = (store) => {
  const { localePath } = useContext();

  if (store.learn_more_url) {
    try {
      return new URL(store.learn_more_url).toString();

    } catch (err) {
      return localePath('/' + store.learn_more_url);
    }
  }
};

export const getMediaGallery = (store) => store.media_gallery && store.media_gallery.length ? store.media_gallery : [];

const storeGetters = {
  getBoutique,
  getId,
  getImage,
  getName,
  getCountryName,
  getPhones,
  getFormattedPhone,
  getEmails,
  getFormattedEmail,
  getFullAddress,
  getPosition,
  getBusinessHoursOutput,
  getLink,
  getMediaGallery
};

export default storeGetters;
