import type { Plugin } from '@nuxt/types';

const plugin : Plugin = (context) => {
  if (context.$config.chat2deskToken) {
    window.chat24_token = context.$config.chat2deskToken;
    window.lang = context.$config.chat2deskLang || 'en';
    window.chat24_url = 'https://livechatv2.chat2desk.com';
    window.chat24_socket_url ='wss://livechatv2.chat2desk.com/widget_ws_new';
    window.chat24_static_files_domain = 'https://storage.chat2desk.com/';
    window.chat24_show_new_wysiwyg = true;

    fetch(`${window.chat24_url}/packs/manifest.json?nocache=${new Date().getTime()}`)
      .then(res => res.json())
      .then(data => {
        const chat24 = document.createElement('script');
        chat24.type = 'text/javascript';
        chat24.async = true;
        chat24.src = ''.concat(window.chat24_url).concat(data['application.js']);
        document.body.appendChild(chat24);
        }
      );
  }
};

export default plugin;
