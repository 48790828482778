















































































































































import {
  SfIcon,
  SfButton,
  SfProductCardHorizontal,
  SfLink,
  SfHeading,
} from '~/components';

import {
  defineComponent,
  ref,
  useFetch,
  useContext,
  onMounted,
  onBeforeUnmount,
} from '@nuxtjs/composition-api';

import {
  useImage,
  useProduct,
  useApi
} from '~/composables';

import {
  getOptimizedProductThumbnailImage,
  getName,
  getPrice,
} from '~/modules/catalog/product/getters/productGetters';

import type { Product } from '~/modules/catalog/product/types';

import { productPresentationQuery } from '~/customQueries/productPresentationQuery';

import Vue from 'vue';
import VueTippy from 'vue-tippy/dist/vue-tippy.esm';

Vue.use(VueTippy);
Vue.component('tippy');

interface ProductShowcaseGroup {
  identifier: string;
  items: {
    items: Product[];
  };
}

interface ProductsResponse {
  data?: {
    productShowcaseGroups?: {
      items: ProductShowcaseGroup[];
    };
  };
}

export default defineComponent({
  name: 'PresentationProduct',
  components: {
    SfHeading,
    SfLink,
    SfIcon,
    SfButton,
    SfProductCardHorizontal,
    Carousel: () => import('~/components/togas/Carousel.vue'),
  },
  setup() {
    const { app } = useContext();
    const { query } = useApi();
    const { getProductPath } = useProduct();
    const { getMagentoImage } = useImage();

    const loading = ref<Boolean>(false);
    const productListRefs = ref<Array<HTMLElement | null>>([]);
    const isShowProductsList = ref(false);

    const carouselSettings = {
      breakpoints: {
        1024: {
          slidesPerView: 1,
          slidesPerGroup: 1,
        },
        768: {
          slidesPerView: 1,
          slidesPerGroup: 1,
        },
        480: {
          slidesPerView: 1,
          slidesPerGroup: 1,
        },
      },
      loop: false,
    };

    const desktopProducts = ref([]);
    const mobileProducts = ref([]);

    const handleProductListClick = (event) => {
      const target = event.target;
      for (const ref of productListRefs.value) {
        if (ref && ref.contains(target)) {
          isShowProductsList.value = true;
          return;
        }
      }
      isShowProductsList.value = false;
    };
    const handleDocumentClick = (event) => {
      const target = event.target;
      const isViewButtonClicked = event.target.closest('.presentation--view-btn');

      if (isViewButtonClicked) {
        return;
      }

      for (const ref of productListRefs.value) {
        if (ref && ref.contains(target)) {
          return;
        }
      }
      isShowProductsList.value = false;
    };


    const tippySettings = {
      trigger: 'click',
      distance: '-15',
      interactive: true
    };
    const renderTippyContent = (product) => {
      const link = `${app.context.base.slice(0, -1)}${app.localePath(getProductPath(product))}`;
      const regularPrice = app.context.$fc(getPrice(product).regular);
      const specialPrice = app.context.$fc(getPrice(product).special);

      if (specialPrice === regularPrice || specialPrice.includes('€0.00') || specialPrice.includes('$0.00')) {
        return `
          <a class="sf-product-card-horizontal presentation__tippy-product" href="${link}">
            <div class="sf-product-card-horizontal__image-wrapper presentation__tippy-product__image-wrapper ">
              <img src="${product.product_thumbnail}" alt="">
            </div>
            <div>
              <div class="presentation__tippy-product__name">${product.name}</div>
              <div class="sf-product-card__price sf-price">
                <span class="sf-price__regular">${regularPrice}</span>
              </div>
            </div>
          </a>
      `;
      }

      if (specialPrice && specialPrice !== regularPrice) {
        return `
          <a class="sf-product-card-horizontal presentation__tippy-product" href="${link}">
            <div class="sf-product-card-horizontal__image-wrapper presentation__tippy-product__image-wrapper">
                <img src="${product.product_thumbnail}" alt="">
            </div>
            <div>
              <div>${product.name}</div>
              <div class="sf-product-card__price sf-price">
                <span class="sf-price__regular display-none"> ${regularPrice}</span>
                <ins class="sf-price__special">${specialPrice}</ins>
                <del class="sf-price__old"> ${regularPrice}</del>
              </div>
            </div>
          </a>
      `;
      }
    };

    const queryVariables = {
      identifiers: ['homepage_desktop', 'homepage_mobile']
    };

    useFetch(async () => {
      try {
        loading.value = true;
        const productsResponse: ProductsResponse = await query(productPresentationQuery, queryVariables);
        desktopProducts.value = productsResponse.data?.productShowcaseGroups?.items?.find(group => group.identifier === 'homepage_desktop')?.items?.items || [];
        mobileProducts.value = productsResponse.data?.productShowcaseGroups?.items?.find(group => group.identifier === 'homepage_mobile')?.items?.items || [];
      } catch (error) {
        console.error('Error in PresentationProduct.vue: ', error);
      } finally {
        loading.value = false;
      }
    });

    onMounted(() => {
      document.addEventListener('click', handleDocumentClick);
    });

    onBeforeUnmount(() => {
      document.removeEventListener('click', handleDocumentClick);
    });

    return {
      loading,
      getOptimizedProductThumbnailImage,
      getName,
      getPrice,
      getMagentoImage,
      getProductPath,
      desktopProducts,
      mobileProducts,
      productListRefs,
      isShowProductsList,
      handleProductListClick,
      renderTippyContent,
      tippySettings,
      carouselSettings
    };
  },
});
