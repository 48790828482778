import { ref } from "@nuxtjs/composition-api";
import { useApi } from "~/composables/";
import { countryQuery } from "../../queries/getTogasCountry";

export function useCountries() {
  const { query } = useApi();
  const loadingCountries = ref(false);

  const loadCountries = async () => {
    let result = ref();

    try {
      loadingCountries.value = true;
      result.value = await query(countryQuery);
    } catch (error) {
      console.warn("Error: ", error);
    } finally {
      loadingCountries.value = false;
    }

    return result;
  };

  return {
    loadCountries,
    loadingCountries,
  };
}

export default useCountries;
