import { Logger } from '~/helpers/logger';
import {Cart, CustomerCartQuery, RemoveItemFromCartInput} from '~/modules/GraphQL/types';
import { VsfContext } from '~/composables/context';
import { CustomQuery, CustomHeaders } from '~/types/core';
import removeItemFromCart from "~/modules/checkout/queries/removeItemFromCart";

export const removeItemCommand = {
  execute: async (
    context: VsfContext,
    {
      currentCart,
      product,
      customQuery,
      customHeaders,
    },
  ) => {
    Logger.debug('[Magento]: Remove item from cart', {
      product,
      currentCart,
    });

    const item = currentCart.items.find((cartItem) => cartItem.uid === product.uid);

    if (!item) {
      return;
    }

    const removeItemParams: RemoveItemFromCartInput = {
      cart_id: currentCart.id,
      cart_item_uid: item.uid,
    };

    // const { data } = await context.$magento.api.removeItemFromCart(
    //   removeItemParams,
    //   customQuery as CustomQuery,
    //   customHeaders as CustomHeaders,
    // );

    // @ts-ignore
    const { data } = await context.$magento.api.customQuery({
      query: removeItemFromCart,
      queryVariables: { input: removeItemParams }
    }) as unknown as CustomerCartQuery;
    Logger.debug('[Result]:', { data });

    return data.removeItemFromCart.cart as unknown as Cart;
  },
};
