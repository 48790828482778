import { render, staticRenderFns } from "./PresentationProduct.vue?vue&type=template&id=54274b85&scoped=true&"
import script from "./PresentationProduct.vue?vue&type=script&lang=ts&"
export * from "./PresentationProduct.vue?vue&type=script&lang=ts&"
import style0 from "./PresentationProduct.vue?vue&type=style&index=0&id=54274b85&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54274b85",
  null
  
)

export default component.exports