export const PromoGroupQuery = `
query PromoBannerGroups($identifier: String) {
  promoBannerGroups(
    filter: { is_enabled: { eq: 1 }, identifier: { eq: $identifier } }
  ) {
    items {
      identifier
      title
      callout
      description
      css_classes
      url
      image_url
      items {
        title
        subtitle
        callout
        url
        image_desktop_url
        image_mobile_url
        video_desktop_url
        video_desktop_preview_url
        video_mobile_url
        video_mobile_preview_url
        css_classes
      }
    }
  }
}
`;
