import { VsfContext } from '~/composables/context';
import { Logger } from '~/helpers/logger';
import {
  Cart,
  CartItemInput, CustomerCartQuery,
} from '~/modules/GraphQL/types';
import { CustomQuery, CustomHeaders } from '~/types/core';
import addProductsToCart from "~/modules/checkout/queries/addProductsToCart";

/** Params object used to add items to a cart */
export declare type AddProductsToCartInput = {
  cartId: string;
  cartItems: CartItemInput[];
};

export const addItemCommand = {
  execute: async (
    context: VsfContext,
    {
      product,
      quantity,
      currentCart,
      productConfiguration,
      customQuery,
      customHeaders,
    },
  ) => {
    Logger.debug('[Magento]: Add item to cart', {
      product,
      quantity,
      currentCart,
    });

    const apiState = context.$magento.config.state;
    const cartId = apiState.getCartId();

    if (!product) {
      return;
    }
    // eslint-disable-next-line no-underscore-dangle
    switch (product.__typename) {
      case 'SimpleProduct':
        const simpleCartInput: AddProductsToCartInput = {
          cartId,
          cartItems: [
            {
              quantity,
              sku: product.sku,
            },
          ],
        };

        // const simpleProduct = await context.$magento.api.addProductsToCart(
        //   simpleCartInput,
        //   customQuery as CustomQuery,
        //   customHeaders as CustomHeaders,
        // );

        // @ts-ignore
        const { data: simpleProductData } = await context.$magento.api.customQuery({
          query: addProductsToCart,
          queryVariables: simpleCartInput
        }) as unknown as CustomerCartQuery;
        Logger.debug('[Result]:', { data: simpleProductData });

        if (simpleProductData.addProductsToCart.user_errors.length > 0) {
          throw new Error(String(simpleProductData.addProductsToCart.user_errors[0].message));
        }
        return simpleProductData.addProductsToCart.cart as unknown as Cart;

      case 'ConfigurableProduct':
        const selectedOptions = Object.values(productConfiguration as object);

        const configurableCartInput: AddProductsToCartInput = {
          cartId,
          cartItems: [
            {
              quantity,
              sku: product.sku,
              selected_options: selectedOptions,
            },
          ],
        };

        // const configurableProduct = await context.$magento.api.addProductsToCart(
        //   configurableCartInput,
        //   customQuery as CustomQuery,
        //   customHeaders as CustomHeaders,
        // );

        // @ts-ignore
        const { data: configurableProductData } = await context.$magento.api.customQuery({
          query: addProductsToCart,
          queryVariables: configurableCartInput
        }) as unknown as CustomerCartQuery;
        Logger.debug('[Result]:', { data: configurableProductData });

        if (configurableProductData.addProductsToCart.user_errors.length > 0) {
          throw new Error(String(configurableProductData.addProductsToCart.user_errors[0].message));
        }
        return configurableProductData.addProductsToCart.cart as unknown as Cart;

      case 'BundleProduct':
        const createEnteredOptions = () =>
        // eslint-disable-next-line implicit-arrow-linebreak
          product.bundle_options.map((bundleOption) => ({
            ...bundleOption,
            value: bundleOption.value.toString(),
          }));

        const bundleCartInput: AddProductsToCartInput = {
          cartId,
          cartItems: [
            {
              quantity,
              sku: product.sku,
              entered_options: createEnteredOptions(),
            },
          ],
        };

        // const bundleProduct = await context.$magento.api.addProductsToCart(
        //   bundleCartInput,
        //   customQuery as CustomQuery,
        //   customHeaders as CustomHeaders,
        // );

        // @ts-ignore
        const { data: bundleProductData } = await context.$magento.api.customQuery({
          query: addProductsToCart,
          queryVariables: bundleCartInput
        }) as unknown as CustomerCartQuery;
        Logger.debug('[Result]:', { data: bundleProductData });

        if (bundleProductData.addProductsToCart.user_errors.length > 0) {
          throw new Error(String(bundleProductData.addProductsToCart.user_errors[0].message));
        }
        return bundleProductData.addProductsToCart.cart as unknown as Cart;

      case 'DownloadableProduct':
        const downloadableCartInput: AddProductsToCartInput = {
          cartId,
          cartItems: [
            {
              quantity,
              sku: product.sku,
            },
          ],
        };

        // const downloadableProduct = await context.$magento.api.addProductsToCart(
        //   downloadableCartInput,
        //   customQuery as CustomQuery,
        //   customHeaders as CustomHeaders,
        // );

        // @ts-ignore
        const { data: downloadableProductData } = await context.$magento.api.customQuery({
          query: addProductsToCart,
          queryVariables: downloadableCartInput
        }) as unknown as CustomerCartQuery;
        Logger.debug('[Result DownloadableProduct]:', { data: downloadableProductData });

        if (downloadableProductData.addProductsToCart.user_errors.length > 0) {
          throw new Error(String(downloadableProductData.addProductsToCart.user_errors[0].message));
        }
        return downloadableProductData.addProductsToCart.cart as unknown as Cart;

      case 'VirtualProduct':
        const virtualCartInput: AddProductsToCartInput = {
          cartId,
          cartItems: [
            {
              quantity,
              sku: product.sku,
            },
          ],
        };

        // const virtualProduct = await context.$magento.api.addProductsToCart(
        //   virtualCartInput,
        //   customQuery as CustomQuery,
        //   customHeaders as CustomHeaders,
        // );

        // @ts-ignore
        const { data: virtualProductData } = await context.$magento.api.customQuery({
          query: addProductsToCart,
          queryVariables: virtualCartInput
        }) as unknown as CustomerCartQuery;
        Logger.debug('[Result VirtualProduct]:', { data: virtualProductData });

        if (virtualProductData.addProductsToCart.user_errors.length > 0) {
          throw new Error(String(virtualProductData.addProductsToCart.user_errors[0].message));
        }
        return virtualProductData.addProductsToCart.cart as unknown as Cart;

      case 'GroupedProduct':
        const groupedCartInput: AddProductsToCartInput = {
          cartId,
          cartItems: product.items.map((item) => ({
            quantity,
            sku: item.product.sku,
          })),
        };

        // const groupedProduct = await context.$magento.api.addProductsToCart(
        //   groupedCartInput,
        //   customQuery as CustomQuery
        // );

        // @ts-ignore
        const { data: groupedProductData } = await context.$magento.api.customQuery({
          query: addProductsToCart,
          queryVariables: groupedCartInput
        }) as unknown as CustomerCartQuery
        Logger.debug('[Result GroupedProduct]:', { data: groupedProductData });

        if (groupedProductData.addProductsToCart.user_errors.length > 0) {
          throw new Error(String(groupedProductData.addProductsToCart.user_errors[0].message));
        }
        return groupedProductData.addProductsToCart.cart as unknown as Cart;

      default:
        // eslint-disable-next-line no-underscore-dangle
        throw new Error(`Product Type ${product.__typename} not supported in add to cart yet`);
    }
  },
};
