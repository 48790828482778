export const countryQuery = `
  {
    countrySelector {
      items {
      country_id
      is_enabled
      name
      url
      is_primary
      currency_symbol
      position
      icon_url
      }
    }
  }
`;
