const productFragment = `
fragment ProductFragment on ProductInterface {
  name
  sku
  product_thumbnail
  uid
  url_key
  url_rewrites {
    url
  }
  price_range {
    maximum_price {
      final_price {
        currency
        value
      }
      regular_price {
        currency
        value
      }
    }
    minimum_price {
      final_price {
        currency
        value
      }
      regular_price {
        currency
        value
      }
    }
  }
}
`;

const productShowcaseMarkerFragment = `
fragment ProductShowcaseMarkerFragment on ProductShowcaseMarker {
  position_top
  position_left
  product {
    ...ProductFragment
  }
}
${productFragment}
`;

const productShowcaseFragment = `
fragment ProductShowcaseFragment on ProductShowcase {
  background_image_url
  markers_label
  markers_color
  markers {
    ...ProductShowcaseMarkerFragment
  }
}
${productShowcaseMarkerFragment}
`;

const productShowcasesFragment = `
fragment ProductShowcasesFragment on ProductShowcases {
  total_count
  items {
    ...ProductShowcaseFragment
  }
}
${productShowcaseFragment}
`;

const productShowcaseGroupFragment = `
fragment ProductShowcaseGroupFragment on ProductShowcaseGroup {
  identifier
  items(filter: {is_enabled: {eq: "1"}}) {
    ...ProductShowcasesFragment
  }
}
${productShowcasesFragment}
`;

const productShowcaseGroupsFragment = `
fragment ProductShowcaseGroupsFragment on ProductShowcaseGroups {
  total_count
  items {
    ...ProductShowcaseGroupFragment
  }
}
${productShowcaseGroupFragment}
`;

export const productPresentationQuery = `
query ProductPresentationQuery($identifiers: [String!]!) {
  productShowcaseGroups(
    filter: { identifier: { in: $identifiers }, is_enabled: { eq: "1" } }
  ) {
    ...ProductShowcaseGroupsFragment
  }
}
${productShowcaseGroupsFragment}
`;
