<template>
  <div
    v-if="!loading && banners.length"
    class="promo-banner-with-meta"
  >
    <div class="promo-banner-with-meta-wrapper">
      <div class="meta-info">
        <div class="meta-info--text-wrapper">
          <SfLink
            class="meta-info__icon-link-mob mobile-only"
            :aria-label="`Go to ${bannerGroupMetaInfo.title} category`"
            :link="decodeURIComponent(localePath(formatAbsoluteUrlToLocal(bannerGroupMetaInfo.url, $config)))"
          >
            <img
              class="meta-info__icon"
              :class="iconClass"
              :src="bannerGroupMetaInfo.image_url"
              alt="banner group logo"
            >
          </SfLink>
          <SfLink
            class="mobile-only"
            :link="decodeURIComponent(localePath(formatAbsoluteUrlToLocal(bannerGroupMetaInfo.url, $config)))"
          >
            <SfHeading
              class="meta-info__heading sf-heading--left mobile-only"
              :title="bannerGroupMetaInfo.title"
              :level="2"
              :style-level="1"
            />
          </SfLink>
          <SfHeading
            class="meta-info__heading sf-heading--left desktop-only"
            :title="bannerGroupMetaInfo.title"
            :level="2"
            :style-level="1"
          />
          <div v-html="bannerGroupMetaInfo.description" class="meta-info__description" />
        </div>
        <SfLink
          class="meta-info__icon-wrapper desktop-only"
          :link="decodeURIComponent(localePath(formatAbsoluteUrlToLocal(bannerGroupMetaInfo.url, $config)))"
        >
          <img
            class="meta-info__icon"
            :class="iconClass"
            :src="bannerGroupMetaInfo.image_url"
            alt=""
          >
          <span class="meta-info__icon-link-desk">{{ bannerGroupMetaInfo.callout }}</span>
        </SfLink>
      </div>
      <carousel
        :settings="carouselSettings"
        :container-class="`${promoBannerGroupType.identifier}-carousel`"
        :is-show-navigation="true"
        white-bullets
        @carousel-initialized="isCarouselInit = true"
      >
        <template>
          <template v-if="isCarouselInit">
            <SfLink
              v-for="banner in banners"
              class="promo-banner-with-meta__slide swiper-slide"
              :link="decodeURIComponent(localePath(formatAbsoluteUrlToLocal(banner.url, $config)))"
              :key="banner.title"
            >
              <picture>
                <source
                  :srcset="banner.image_desktop_url"
                  media="screen and (min-width: 1024px)"
                />
                <img
                  class="main-carousel__item-image"
                  :src="banner.image_mobile_url"
                  :alt="`Link to ${banner.title} category page`"
                  loading="lazy"
                />
              </picture>
              <div class="promo-banner-with-meta__slide--title">
                <hr class="sf-divider sf-divider--white mobile-only">
                <SfHeading
                  class="sf-heading--left mobile-only"
                  :title="banner.title"
                  :level="2"
                  :style-level="4"
                />
                <SfHeading
                  class="sf-heading--left desktop-only"
                  :title="banner.title"
                  :level="2"
                  :style-level="3"
                />
                <hr class="sf-divider sf-divider--white desktop-only">
                <span class="promo-banner-with-meta__slide--link desktop-only">{{ banner.callout }}</span>
              </div>
            </SfLink>
          </template>
          <SkeletonLoader
            v-else
            skeleton-class="promo-banner-with-meta__carousel-skeleton"
            :dark-shimmer="true"
          />
        </template>
      </carousel>
    </div>
  </div>
  <SkeletonLoader
    v-else
    skeleton-class="promo-banner-with-meta__skeleton"
    :dark-shimmer="true"
  />
</template>
<script>
import {
  ref,
  computed,
  useFetch
} from '@nuxtjs/composition-api';
import { useApi } from '~/composables';
import { PromoGroupQuery } from '~/customQueries/promoGroupQuery';
import { formatAbsoluteUrlToLocal } from '~/utilities/helpers';

import {
  SfButton,
  SfHeading,
  SfLink,
  SkeletonLoader,
} from '~/components';

export default {
  name: 'PromoBannerWithMeta',
  components: {
    SfButton,
    SfHeading,
    SfLink,
    SkeletonLoader,
    Carousel: () => import('~/components/togas/Carousel.vue'),
  },
  props: {
    promoBannerGroupType: {
      type: Object,
      require: true
    },
  },
  setup(props) {
    const {query} = useApi();
    const bannerGroupMetaInfo = ref({});
    const banners = ref([]);
    const carouselSettings = {
      loop: false,
      spaceBetween: 8,
      breakpoints: {
        1400: {
          slidesPerView: 2.7,
          slidesPerGroup: 1,
        },
        1200: {
          slidesPerView: 2,
          slidesPerGroup: 1,
        },
        768: {
          slidesPerView: 2.7,
          slidesPerGroup: 1,
        },
        480: {
          slidesPerView: 2.7,
          slidesPerGroup: 1,
        },
        0: {
          slidesPerView: 2.7,
          slidesPerGroup: 1,
        },
      },
    };
    const iconClass = computed(() => props.promoBannerGroupType.identifier);
    const loading = ref(false);
    const isCarouselInit = ref(false);

    useFetch(async () => {
      try {
        loading.value = true;
        const response = await query(PromoGroupQuery, props.promoBannerGroupType);
        bannerGroupMetaInfo.value = response.data?.promoBannerGroups?.items[0];
        banners.value = response.data?.promoBannerGroups?.items[0]?.items;
      }
      catch (error) {
        console.error('Error in PromoBannerWithMeta.vue: ', error);
      }
      finally {
        loading.value = false;
      }
    });

    return {
      bannerGroupMetaInfo,
      banners,
      carouselSettings,
      iconClass,
      loading,
      isCarouselInit,
      formatAbsoluteUrlToLocal
    };
  }
};
</script>
<style lang="scss" scoped>
.promo-banner-with-meta {
  height: auto;
  color: var(--togas-white-color);

  --heading-color: var(--togas-white-color);

  @include for-desktop {
    height: 650px;
  }

  &-wrapper {
    padding: var(--spacer-lg) 0 var(--spacer-lg) var(--spacer-sm);

    @include for-desktop {
      max-width: var(--togas-content-max-width);
      display: flex;
      gap: 40px;
      padding: 70px 0 70px 40px;
    }

    @include for-desktop($desktop-xl-min) {
      left: 50%;
      transform: translateX(-50%);
      position: absolute;
    }
  }

  .meta-info {
    margin-bottom: var(--spacer-base);

    @include for-desktop {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 330px;
      flex-shrink: 0;
      margin-bottom: 0;
    }
  }

  .meta-info__description {

    @include for-mobile {
      margin-bottom: var(--spacer-base);
    }

    .text {
      font: var(--font-weight--normal) var(--font-size--base) / var(--line-height--) var(--font-family--secondary);

      &:not(last-of-type) {
        margin-bottom: var(--spacer-sm);
      }
    }
  }

  .meta-info__icon-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: var(--spacer-xl);
  }

  .meta-info__icon {
    display: block;
    margin-bottom: var(--spacer-base);

    @include for-desktop {
      margin-bottom: var(--spacer-lg);
    }

    &.sleep-system {
      width: 60px;
      height: 76px;

      @include for-desktop {
        width: 93px;
        height: 118px;
      }
    }

    &.technologies {
      width: 125px;
      height: 76px;

      @include for-desktop {
        width: 130px;
        height: 80px;
      }
    }
  }

  .meta-info__heading {
    --heading-title-font: 400 36px / 36px var(--font-family--display-pro);
    --heading-margin: 0 0 var(--spacer-sm);

    @include for-desktop {
      --heading-title-font: 400 40px / 40px var(--font-family--display-pro);
      --heading-margin: 0 0 var(--spacer-base);
    }
  }

  .meta-info__icon-link-desk {
    color: var(--togas-white-color);
    font: var(--font-weight--normal) var(--font-size--base) / var(--line-height--base) var(--font-family--secondary);
  }

  .meta-info__icon-link-mob {
    display: flex;
    justify-content: center;
    margin: 0 auto var(--spacer-base);
  }

  .promo-banner-with-meta-carousel {
    display: flex;
    flex-direction: column;
  }

  .promo-banner-with-meta__slide {
    position: relative;

    @include for-desktop {
      max-width: 330px;
    }
  }

  .promo-banner-with-meta__slide--title {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: var(--spacer-xs);
    --heading-margin: 0;
    --link-text-decoration: none;

    @include for-desktop {
      top: 0;
      padding: 30px;
    }

    .sf-heading {
      --heading-title-font: 400 18px / 18px var(--font-family--display-pro);

      @include for-desktop {
        --heading-title-font: 500 24px / 32px var(--font-family--display-pro);
      }
    }
  }

  .promo-banner-with-meta__slide--link {
    display: inline-block;
    margin-top: var(--spacer-xs);
    color: var(--togas-white-color);
    font: var(--font-weight--normal) var(--font-size--sm) / var(--line-height--xs) var(--font-family--secondary);
    text-decoration: underline;
  }

  .swiper-container {
    height: max-content;
    width: 100%;

    @include for-desktop {
      width: 1001px;
    }
  }

  &__skeleton {
    width: 100%;
    height: 600px;
    margin-bottom: var(--spacer-m);

    @include for-desktop {
      height: 650px;
    }

    .skeleton {
      height: 100%;
    }
  }

  &__carousel-skeleton {
    height: 200px;

    @include for-desktop {
      height: 500px;
    }
  }
}
</style>
