import { Logger } from '~/helpers/logger';
import {Cart, CustomerCartQuery, UpdateCartItemsInput} from '~/modules/GraphQL/types';
import { VsfContext } from '~/composables/context';
import updateCartItems from "~/modules/checkout/queries/updateCartItems";

export const updateItemQtyCommand = {
  execute: async (context: VsfContext, {
    currentCart,
    product,
    quantity,
    customQuery = { updateCartItems: 'updateCartItems' },
    customHeaders = {},
  }) => {
    Logger.debug('[Magento]: Update product quantity on cart', {
      product,
      quantity,
      currentCart,
    });

    const updateCartParams: UpdateCartItemsInput = {
      cart_id: currentCart.id,
      cart_items: [
        {
          cart_item_uid: product.uid,
          quantity,
        },
      ],
    };

    // @ts-ignore
    const { data, errors } = await context.$magento.api.customQuery({
      query: updateCartItems,
      queryVariables: { input: updateCartParams }
    }) as unknown as CustomerCartQuery;

    Logger.debug('[Result]:', data);

    return data?.updateCartItems
      ? data.updateCartItems.cart as unknown as Cart
      : errors[0].message as unknown as Cart;
  },
};
