<template>
  <div class="sf-video--wrapper">
    <video
      ref="video"
      class="sf-video"
      playsinline loop
      :muted="muted"
      :autoplay="autoPlay"
      :poster="poster"
    >
      <source
        type="video/mp4; codecs=&quot;avc1.42E01E, mp4a.40.2&quot;"
        :src="src"
      />
    </video>
    <SfButton
      v-if="src"
      class="sf-video--button sf-button--pure"
      @click="handleClick"
    >
      <SfIcon
        class="sf-video--button-icon"
        :color="buttonColor"
        :icon="state"
      />
    </SfButton>
  </div>
</template>
<script>
import { defineComponent } from '@nuxtjs/composition-api';
import { SfButton, SfIcon } from '~/components';

export default defineComponent({
  name: 'SfVideo',
  components: {
    SfButton,
    SfIcon,
  },
  props: {
    src: {
      type: String,
      default: '',
    },
    poster: {
      type: String,
      default: '',
    },
    autoPlay: {
      type: Boolean,
      default: false,
    },
    muted: {
      type: Boolean,
      default: true,
    },
    buttonColor: {
      type: String,
      default: '#0F1319',
    },
  },
  data() {
    return {
      state: this.autoPlay ? 'pause' : 'play',
    }
  },
  methods: {
    handleClick() {
      if (this.state === 'play') {
        this.$refs.video.play();
        this.state = 'pause';
      } else {
        this.$refs.video.pause();
        this.state = 'play';
      }
    },
  }
})
</script>

<style lang="scss">
@import "~@/styles/components/molecules/SfVideo";
</style>
