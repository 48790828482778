import { ref, onMounted, useContext } from '@nuxtjs/composition-api';
import { Logger } from "~/helpers/logger";

export const useTopBar = () => {
  const { app } = useContext();
  const hasStoresToSelect = ref<boolean>(false);
  const hasCurrencyToSelect = ref<boolean>(false);

  onMounted(async () => {
    try {
      Logger.debug('useTopBar [Request]');

      const { data, errors } = await app.$vsf.$magento.api.checkStoresAndCurrency();

      if (errors) {
        const joinedErrors = errors.map((e) => e.message).join(',');
        Logger.error('useTopBar', joinedErrors);
      }

      hasStoresToSelect.value = data?.availableStores?.length > 1 ?? false;
      hasCurrencyToSelect.value = data?.currency?.available_currency_codes?.length > 1 ?? false;

      Logger.debug('useTopBar [Result]', data);
    } catch (err) {
      Logger.error('useTopBar [Errors]', err);
    }
  });

  return {
    hasStoresToSelect,
    hasCurrencyToSelect,
  };
};

export default useTopBar;
