<template>
  <carousel
    v-if="!loading && banners.length"
    :settings="carouselSettings"
    :container-class="'fashion-carousel'"
    inside-bullets
    white-bullets
  >
    <div
      v-for="(banner, i) in banners"
      :key="banner.title"
      class="fashion-carousel__slide swiper-slide"
    >
      <div class="fashion-carousel__item-wrapper">
        <video
          v-if="banner.video_desktop_url"
          class="desktop-only"
          :class="videoRight(banner)"
          width="100%"
          height="100%"
          muted
          autoplay
          playsinline
          loop
          :poster="banner.video_desktop_preview_url"
        >
          <source
            type="video/mp4; codecs=&quot;avc1.42E01E, mp4a.40.2&quot;"
            :src="banner.video_desktop_url"
          />
          <track src="assets/sub.vtt" kind="captions" srclang="en">
        </video>
        <img
          v-if="!banner.video_desktop_url && banner.video_desktop_preview_url"
          class="fashion-carousel__image-wrapper--small desktop-only img-block"
          :src="banner.video_desktop_preview_url" alt=""
        >
        <div class="fashion-carousel__image-wrapper">
          <picture
            class="fashion-carousel__item-image-container"
          >
            <source
              :srcset="banner.image_desktop_url"
              media="screen and (min-width: 1024px)"
            />
            <img
              :src="banner.image_mobile_url"
              alt=""
              class="fashion-carousel__item-image"
              height="710"
              width="412"
              :fetchpriority="i === 0 ? 'high' : 'auto'"
              :alt="banner.title"
            />
          </picture>
          <div
            class="fashion-carousel__content"
            :class="banner.css_classes"
          >
            <p class="fashion-carousel__content--subtitle">{{ banner.subtitle }}</p>
            <h1 class="fashion-carousel__content--title">{{ banner.title }}</h1>
            <SfLink
              class="fashion-carousel__content--link swiper-no-swiping"
              :link="decodeURIComponent(localePath(formatAbsoluteUrlToLocal(banner.url, $config)))"
              :style="{ color: colorByClass(banner) }"
            >
              {{ banner.callout }}
            </SfLink>
          </div>
        </div>
      </div>
    </div>
  </carousel>
  <SkeletonLoader
    v-else
    skeleton-class="main-carousel__skeleton"
    :dark-shimmer="true"
  />
</template>
<script>
import {
  ref,
  useFetch
} from '@nuxtjs/composition-api';
import { useApi } from '~/composables';
import { PromoGroupQuery } from '~/customQueries/promoGroupQuery';
import { formatAbsoluteUrlToLocal } from '~/utilities/helpers';

import {
  SfLink,
  SkeletonLoader,
} from '~/components';
import { PromoBannerGroupType } from '~/constants';

export default {
  name: 'FashionSlider',
  components: {
    SfLink,
    SkeletonLoader,
    Carousel: () => import('~/components/togas/Carousel.vue'),
  },
  setup() {
    const {query} = useApi();
    const banners = ref([]);
    const carouselSettings = {
      autoplay: {
        delay: 6000,
      },
      breakpoints: {
        1024: {
          slidesPerView: 1,
          slidesPerGroup: 1,
        },
        768: {
          slidesPerView: 1,
          slidesPerGroup: 1,
        },
        480: {
          slidesPerView: 1,
          slidesPerGroup: 1,
        },
      },
    };

    const loading = ref(false);

    const videoRight = (banner) => banner?.css_classes?.includes('video-right') ? 'video-right' : '';
    const colorByClass = (banner) => banner?.css_classes?.includes('text-white') ? 'white' : 'initial';

    useFetch(async () => {
      try {
        loading.value = true;
        const response = await query(PromoGroupQuery, PromoBannerGroupType.FASHION_SLIDER);
        banners.value = response.data?.promoBannerGroups?.items[0]?.items;
      }
      catch (error) {
        console.error('Error in FashionSlider.vue: ', error);
      }
      finally {
        loading.value = false;
      }
    });

    return {
      banners,
      carouselSettings,
      loading,
      videoRight,
      colorByClass,
      formatAbsoluteUrlToLocal
    };
  }
};
</script>
<style lang="scss" scoped>
.fashion-carousel {

  &__item {
    position: relative;
  }

  &__item-image,
  &__item-video {
    display: block;
    height: auto;
    width: 100%;
  }

  &__item-image {

    @include for-desktop {
      aspect-ratio: 2 / 1.18889;
    }
  }

  &__item-wrapper {
    display: flex;
    align-items: flex-start;

    @include for-desktop {
      margin-bottom: -3px;
    }

    video {
      max-width: 25%;
      height: auto;

      &.video-right {
        order: 1;
      }
    }

    picture {
      width: 100%;
    }
  }

  &__image-wrapper {
    position: relative;
    width: 100%;
  }

  &__image-wrapper--small {
    max-width: 25%;
    aspect-ratio: 2 / 3.57;
  }

  &__content {
    position: absolute;
    top: 76px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
    max-width: var(--togas-content-max-width);
    padding: 0 var(--spacer-sm);
    white-space: normal;
    z-index: 1;

    @include for-desktop {
      top: 180px;
      align-items: flex-start;
      padding: 0 60px;
    }

    &--subtitle {
      font: var(--font-weight--light) 13px /13px var(--font-family--primary);
      text-align: center;
      text-transform: capitalize;
      margin: 0 0 var(--spacer-2xs);

      @include for-desktop {
        font: var(--font-weight--normal) 12px /18px var(--font-family--primary);
        text-align: left;
      }
    }

    &--title {
      font: var(--font-weight--normal) 36px / 36px var(--font-family--display-pro);
      margin: 0 0 var(--spacer-lg);

      @include for-desktop {
        font: var(--font-weight--normal) 40px / 40px var(--font-family--display-pro);
        margin: 0 0 60px;
      }
    }

    &--link {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font: var(--font-weight--normal) var(--font-size--base) / var(--line-height--base) var(--font-family--secondary);
      width: auto;
      height: 38px;
      letter-spacing: 0.05em;
      padding: var(--spacer-m);

      @include for-desktop {
        font: var(--font-weight--normal) var(--font-size--base) / var(--line-height--base) var(--font-family--secondary);
        padding: var(--spacer-m) var(--spacer-m) var(--spacer-m) 0;
      }
    }
  }

  &__skeleton {
    width: 100%;
    height: 80vh;
    margin-bottom: 20px;

    @include for-desktop {
      height: 70vh;
    }

    .skeleton {
      height: 100%;
    }
  }
}
</style>
