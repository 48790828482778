<template>
  <carousel
    v-if="!loading && banners.length"
    :settings="carouselSettings"
    :container-class="'main-carousel'"
    inside-bullets
    white-bullets
  >
    <div
      v-for="(banner, i) in banners"
      :key="banner.title"
      class="main-carousel__slide swiper-slide"
    >
      <div v-if="banner.video_desktop_url || banner.video_mobile_url">
        <video
          class="main-carousel__item-video desktop-only"
          width="100%"
          height="100%"
          muted
          autoplay
          playsinline
          loop
          :poster="banner.video_desktop_preview_url"
        >
          <source
            type="video/mp4; codecs=&quot;avc1.42E01E, mp4a.40.2&quot;"
            :src="banner.video_desktop_url"
          />
          <track src="assets/sub.vtt" kind="captions" srclang="en">
        </video>
        <video
          class="main-carousel__item-video mobile-only"
          width="100%"
          height="100%"
          muted
          autoplay
          playsinline
          loop
          :poster="banner.video_mobile_preview_url"
        >
          <source
            type="video/mp4; codecs=&quot;avc1.42E01E, mp4a.40.2&quot;"
            :src="banner.video_mobile_url"
          />
          <track src="assets/sub.vtt" kind="captions" srclang="en">
        </video>
      </div>
      <picture
        v-else
        class="main-carousel__item-image-container"
      >
        <source
          :srcset="banner.image_desktop_url"
          media="screen and (min-width: 1024px)"
        />
        <img
          class="main-carousel__item-image"
          :src="banner.image_mobile_url"
          :alt="banner.title"
          :loading="i > 0 ? 'lazy' : 'eager'"
        />
      </picture>
      <div
        class="main-carousel__content"
        :class="banner.css_classes"
      >
        <p class="main-carousel__content--subtitle">{{ banner.subtitle }}</p>
        <h1 class="main-carousel__content--title" v-html="banner.title" />
        <SfLink
          class="main-carousel__content--link swiper-no-swiping"
          :link="decodeURIComponent(localePath(formatAbsoluteUrlToLocal(banner.url, $config)))"
          :style="{ color: banner.css_classes === 'text-white' ? 'white' : '' }"
        >
          {{ banner.callout }}
        </SfLink>
      </div>
    </div>
  </carousel>
  <SkeletonLoader
    v-else
    skeleton-class="main-carousel__skeleton"
    :dark-shimmer="true"
  />
</template>
<script>
import {
  ref,
  useFetch
} from '@nuxtjs/composition-api';
import { useApi } from '~/composables';
import { PromoGroupQuery } from '~/customQueries/promoGroupQuery';
import { formatAbsoluteUrlToLocal } from '~/utilities/helpers';

import {
  SfLink,
  SkeletonLoader,
} from '~/components';
import { PromoBannerGroupType } from '~/constants';

export default {
  name: 'MainSlider',
  components: {
    SfLink,
    SkeletonLoader,
    Carousel: () => import('~/components/togas/Carousel.vue'),
  },
  setup() {
    const {query} = useApi();
    const banners = ref([]);
    const carouselSettings = {
      autoplay: {
        delay: 6000,
      },
      speed: 1000,
      breakpoints: {
        1024: {
          slidesPerView: 1,
          slidesPerGroup: 1,
        },
        768: {
          slidesPerView: 1,
          slidesPerGroup: 1,
        },
        480: {
          slidesPerView: 1,
          slidesPerGroup: 1,
        },
      },
    };

    const loading = ref(false);

    useFetch(async () => {
      try {
        loading.value = true;
        const response = await query(PromoGroupQuery, PromoBannerGroupType.MAIN_SLIDER);
        banners.value = response.data?.promoBannerGroups?.items[0]?.items;
      }
      catch (error) {
        console.error('Error in MainSlider.vue: ', error);
      }
      finally {
        loading.value = false;
      }
    });

    return {
      banners,
      carouselSettings,
      loading,
      formatAbsoluteUrlToLocal
    };
  }
};
</script>

<style lang="scss" scoped>
.main-carousel {
  top: -44px;
  margin-bottom: -44px;

  @include for-desktop($desktop-l-min) {
    top: -72px;
    margin-bottom: -72px;;
  }

  &__item {
    position: relative;
  }

  &__item-image,
  &__item-video {
    display: block;
    width: 100%;
    height: 100svh;
    object-fit: cover;

    @include for-desktop {
      aspect-ratio: 2 / 1;
      height: auto;
    }
  }

  &__item-video {

    @include for-desktop {
      object-fit: cover;
    }
  }

  &__content {
    position: absolute;
    top: 76px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
    max-width: var(--togas-content-max-width);
    padding: 0 var(--spacer-sm);
    white-space: normal;
    z-index: 1;

    @include for-desktop {
      top: calc(50% - 85px);
      align-items: flex-start;
      padding: 0 40px;
    }

    &--subtitle {
      font: var(--font-weight--light) 12px /13px var(--font-family--primary);
      text-align: center;
      text-transform: capitalize;
      margin: 0 0 var(--spacer-2xs);

      @include for-desktop {
        font: var(--font-weight--normal) 12px /18px var(--font-family--primary);
        text-align: left;
      }
    }

    &--title {
      font: var(--font-weight--normal) 36px / 36px var(--font-family--display-pro);
      margin: 0 0 var(--spacer-lg);

      @include for-desktop {
        font: var(--font-weight--normal) 40px / 40px var(--font-family--display-pro);
        margin: 0 0 60px;
        text-align: left;
      }
    }

    &--link {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font: var(--font-weight--normal) var(--font-size--base) / var(--line-height--base) var(--font-family--secondary);
      width: auto;
      height: 38px;
      letter-spacing: 0.05em;
      padding: var(--spacer-m);

      @include for-desktop {
        font: var(--font-weight--normal) var(--font-size--base) / var(--line-height--base) var(--font-family--secondary);
        padding: var(--spacer-m) var(--spacer-m) var(--spacer-m) 0;
      }
    }
  }

  &__skeleton {
    width: 100%;
    height: 80vh;
    margin-bottom: 20px;

    @include for-desktop {
      height: 70vh;
    }

    .skeleton {
      height: 100%;
    }
  }
}
</style>
